@import url("../fonts/fonts.css");

body {
  font-family: "PFAgoraSansPro-Regular", "pfagorasanspro-reg_0", sans-serif;
  font-size: 16px;
  color: #202020;
}

.background_nav {
  background-color: #f9f9f9;
  -webkit-transition: all .25s ease;
  -o-transition: all .25s ease;
  transition: all .25s ease;
}

header {
  background: url("../img/inst_header_bg1.jpg") no-repeat center;
  -webkit-background-size: cover;
  background-size: cover;
}

header .top_nav_panel {
  position: fixed;
  width: 100%;
  padding-top: 13px;
  padding-bottom: 13px;
  z-index: 100;
}

header .header_top_col {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

header .main_logo {
  font-family: "BebasNeueRegular", sans-serif;
  font-size: 22px;
  max-width: 310px;
  line-height: 24px;
  color: #202020;
  -webkit-transition: all .25s ease;
  -o-transition: all .25s ease;
  transition: all .25s ease;
  text-decoration: none;

  img {
    margin-top: -20px;
  }
}

header .main_logo:hover {
  color: #ffbF4f;
}

header nav ul {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

header nav ul li {
  margin-right: 38px;
}

header nav ul li:last-of-type {
  margin-right: 0;
}

header nav ul li a {
  font-size: 18px;
  color: #202020;
  -webkit-transition: all .25s ease;
  -o-transition: all .25s ease;
  transition: all .25s ease;
  text-decoration: none;
  border-bottom: 4px solid transparent;
}

header nav ul li a.mPS2id-highlight {
  border-bottom: 4px solid #ffbF4f;
  color: #202020;
}

header nav ul li a:hover {
  border-bottom: 4px solid #ffbF4f;
  color: #202020;
  text-decoration: none;
}

header .header_phone_wrap span {
  font-size: 16px;
  color: #676767;
}

header .header_phone_wrap span:last-of-type {
  font-size: 16px;
  color: #ffbf4f;
}

header .header_phone_wrap p {
  font-family: "BebasNeueRegular", sans-serif;
  margin-bottom: 2px;
  font-size: 28px;
  line-height: 26px;
}

.header_video_wrap {
  padding-top: 200px;
  padding-bottom: 168px;
  background: url("../img/inst_header_bg2.png") no-repeat left 321px bottom;
}

.header_video_wrap h2 {
  max-width: 780px;
  margin-bottom: 30px;
  font-family: "BebasNeueRegular", sans-serif;
  font-size: 42px;
  line-height: 52px;
}

.header_video_wrap h2 b {
  position: relative;
  display: inline-block;
  font-family: "BebasNeueBold", sans-serif;
  letter-spacing: 1px;
  z-index: 10;
}

.header_video_wrap h2 b span {
  position: relative;
  z-index: 10;
}

.header_video_wrap h2 b:after {
  content: '';
  position: absolute;
  display: inline-block;
  left: 0;
  bottom: 10px;
  height: 16px;
  width: 117px;
  background-color: #ffc257;
  z-index: 0;
}

.header_video_wrap .left_col p {
  max-width: 400px;
  font-size: 18px;
  line-height: 24px;
}

.header_video_wrap .left_col .start_training_btn {
  position: relative;
  margin-top: 27px;
}

.header_video_wrap .left_col .start_training_btn:after {
  content: url("../img/icons/inst_arrow_right.png");
  position: absolute;
  top: -35px;
  right: -73px;
  height: 75px;
  width: 55px;
}

.header_video_wrap .header_video {
  -webkit-box-shadow: 0 15px 35px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 15px 35px 5px rgba(0, 0, 0, 0.1);
  max-height: 315px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.header_video_wrap .header_video .youCover {
  padding-top: 0;
}

.header_video_wrap .header_video a {
  max-height: 315px;
}

.header_video_wrap .header_video a img {
  max-height: 315px;
}

.header_video_wrap .header_video_desc {
  margin-top: 25px;
  padding-left: 72px;
  color: #676767;
}

.start_training_btn {
  display: inline-block;
  line-height: 60px;
  width: 225px;
  font-size: 18px;
  color: #ffffff;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
  background: -webkit-linear-gradient(45deg, #ffb22e 0%, #ffc012 100%);
  background: -o-linear-gradient(45deg, #ffb22e 0%, #ffc012 100%);
  background: linear-gradient(45deg, #ffb22e 0%, #ffc012 100%);
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0 5px 0 0 #da9100, 0 15px 30px 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 0 0 #da9100, 0 15px 30px 5px rgba(0, 0, 0, 0.15);
  text-decoration: none;
}

.start_training_btn:hover {
  text-decoration: none;
  color: #ffffff;
  -webkit-box-shadow: 0 5px 0 0 #da9100, 0 15px 30px 5px rgba(0, 0, 0, 0);
  box-shadow: 0 5px 0 0 #da9100, 0 15px 30px 5px rgba(0, 0, 0, 0);
  background: -webkit-linear-gradient(45deg, #ffc012 0%, #ffb22e 100%);
  background: -o-linear-gradient(45deg, #ffc012 0%, #ffb22e 100%);
  background: linear-gradient(45deg, #ffc012 0%, #ffb22e 100%);
}

.advantages {
  background-color: #f9f9f9;
  overflow-x: hidden;
}

.advantages > .container {
  position: relative;
  padding-top: 90px;
  padding-bottom: 100px;
  background: url("../img/icons/inst_circle_bg1.png") no-repeat left 89px top 168px, url("../img/icons/inst_cross_bg1.png") no-repeat right 63px top 85px, url("../img/icons/inst_circle_bg2.png") no-repeat right 63px top 340px, url("../img/icons/inst_rectangle_bg1.png") no-repeat left 151px top 678px, url("../img/icons/inst_circle_bg1.png") no-repeat right 0 top 656px, url("../img/icons/inst_cross_bg1.png") no-repeat left 569px top 832px, url("../img/icons/inst_circle_bg2.png") no-repeat left 0 bottom 126px, url("../img/icons/inst_rectangle_bg2.png") no-repeat right 100px bottom 25px;
}

.advantages > .container .bg_text {
  position: absolute;
  right: -325px;
  top: 531px;
  font-family: "Gilroy-Bold", "gilroy-bold", sans-serif;
  font-size: 200px;
  color: rgba(32, 32, 32, 0.05);
  text-transform: uppercase;
}

.advantages h2 {
  max-width: 620px;
  margin-bottom: 90px;
  margin-left: auto;
  margin-right: auto;
  font-family: "BebasNeueRegular", sans-serif;
  font-size: 48px;
  line-height: 54px;
  text-align: center;
}

.advantages h2 span {
  position: relative;
  z-index: 10;
}

.advantages h2 span:after {
  content: '';
  position: absolute;
  display: block;
  height: 16px;
  width: 238px;
  bottom: 7px;
  right: -8px;
  background-color: #fec157;
  z-index: -1;
}

.advantages .advantages_row_left {
  margin-bottom: 100px;
}

.advantages .advantages_row_left img {
  -webkit-box-shadow: 0 15px 30px 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0 15px 30px 5px rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.advantages .advantages_row_left ul {
  max-width: 605px;
  margin-top: 34px;
  margin-bottom: 0;
  padding-top: 20px;
  padding-bottom:15px;
  padding-left: 57px;
  list-style: none;
  border-top: 8px solid #FDCE7A;
  border-right: 8px solid #FDCE7A;
  border-bottom: 8px solid #FDCE7A;
}

.advantages .advantages_row_left ul li {
  margin-bottom: 15px;
  padding-left: 38px;
  padding-right: 20px;
  font-size: 18px;
  background: url("../img/icons/inst_advantages_arrow.jpg") no-repeat left top;
}

.advantages .advantages_row_right img {
  -webkit-box-shadow: 0 15px 30px 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0 15px 30px 5px rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.advantages .advantages_row_right ul {
  max-width: 605px;
  margin-left: auto;
  margin-top: 85px;
  margin-bottom: 0;
  padding-top: 44px;
  padding-bottom: 28px;
  padding-left: 57px;
  list-style: none;
  border-top: 8px solid #FDCE7A;
  border-left: 8px solid #FDCE7A;
  border-bottom: 8px solid #FDCE7A;
}

.advantages .advantages_row_right ul li {
  margin-bottom: 15px;
  padding-left: 38px;
  padding-right: 20px;
  font-size: 18px;
  background: url("../img/icons/inst_advantages_arrow.jpg") no-repeat left top;

  span {
    font-weight: 700;
  }
}

.advantages .start_training_btn {
  margin-top: 80px;
}

.program {
  padding-top: 89px;
  padding-bottom: 24px;
}

.program h2 {
  margin-bottom: 77px;
  font-family: "BebasNeueRegular", sans-serif;
  font-size: 48px;
  text-align: center;
}

.program h2 span {
  position: relative;
  z-index: 10;
}

.program h2 span:after {
  content: '';
  position: absolute;
  display: block;
  height: 16px;
  width: 195px;
  bottom: 7px;
  right: -6px;
  background-color: #fec157;
  z-index: -1;
}

.program .start_training_btn {
  margin-bottom: 77px;
}

.program .dots_wrap_1 {
  position: absolute;
  bottom: -116px;
  left: 435px;
  z-index: 5;
}

.program .dots_wrap_2 {
  position: absolute;
  bottom: -190px;
  left: 435px;
  z-index: 5;
}

.program .dots_wrap_4 {
  position: absolute;
  bottom: -190px;
  left: 435px;
  z-index: 5;
}

.program .dots_wrap_6 {
  position: absolute;
  bottom: -127px;
  left: 377px;
  z-index: 5;
}

.program .dots_wrap_8 {
  position: absolute;
  bottom: -80px;
  left: 393px;
  z-index: 5;
}

.program .left_img_row {
  position: relative;
  margin-bottom: 100px;
}

.program .left_img_row .program_item_desc {
  max-width: 460px;
}

.program .left_img_row .img_program_wrap {
  position: relative;
  max-height: 440px;
  max-width: 440px;
  margin-left: auto;
  margin-right: auto;
}

.program .left_img_row .img_program_wrap img {
  position: relative;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: 8px solid #FFBF4F;
  z-index: 10;
}

.program .left_img_row .img_program_wrap:before {
  content: url("../img/inst_program_cource_bg1.jpg");
  position: absolute;
  top: -37px;
  right: 12px;
  z-index: 0;
}

.program .left_img_row .img_program_wrap:after {
  content: url("../img/inst_program_cource_bg2.jpg");
  position: absolute;
  z-index: 0;
  left: 14px;
  bottom: -46px;
}

.program .left_img_row .img_program_wrap .num_program {
  position: absolute;
  top: 39px;
  left: 8px;
  font-family: "BebasNeueBold", sans-serif;
  font-size: 100px;
  line-height: 80px;
  color: rgba(255, 179, 46, 0.75);
  z-index: 0;
}

.program .left_img_row h3 {
  margin-top: 5px;
  margin-bottom: 11px;
  font-family: "BebasNeueRegular", sans-serif;
  font-size: 28px;
}

.program .left_img_row h3:after {
  content: "";
  display: block;
  height: 4px;
  width: 164px;
  margin-top: 8px;
  background-color: #ffbf4f;
}

.program .left_img_row h4 {
  margin-bottom: 12px;
  font-family: "PFAgoraSansPro-Italic", "pfagorasanspro-italic_0", sans-serif;
  color: rgba(32, 32, 32, 0.5);
  font-size: 18px;
  text-transform: uppercase;
}

.program .left_img_row ul {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.program .left_img_row ul li {
  line-height: 23px;
  margin-bottom: 15px;
  padding-left: 38px;
  padding-right: 20px;
  font-size: 18px;
  background: url("../img/icons/inst_advantages_arrow.jpg") no-repeat left top; }
.advantages .advantages_row_right ul li span {
  font-weight: 700;
}

.program .right_img_row {
  position: relative;
  margin-bottom: 100px;
}

.program .right_img_row .program_item_desc {
  max-width: 460px;
  margin-left: auto;
}

.program .right_img_row .img_program_wrap {
  position: relative;
  max-height: 440px;
  max-width: 440px;
  margin-left: auto;
  margin-right: auto;
}

.program .right_img_row .img_program_wrap img {
  position: relative;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: 8px solid #FFBF4F;
  z-index: 10;
}

.program .right_img_row .img_program_wrap:before {
  content: url("../img/inst_program_cource_bg1.jpg");
  position: absolute;
  top: -37px;
  right: 12px;
  z-index: 0;
}

.program .right_img_row .img_program_wrap:after {
  content: url("../img/inst_program_cource_bg2.jpg");
  position: absolute;
  z-index: 0;
  left: 14px;
  bottom: -46px;
}

.program .right_img_row .img_program_wrap .num_program {
  position: absolute;
  top: 39px;
  left: 8px;
  font-family: "BebasNeueBold", sans-serif;
  font-size: 100px;
  line-height: 80px;
  color: rgba(255, 179, 46, 0.75);
  z-index: 0;
}

.program .right_img_row h3 {
  margin-top: 5px;
  margin-bottom: 11px;
  font-family: "BebasNeueRegular", sans-serif;
  font-size: 28px;
}

.program .right_img_row h3:after {
  content: "";
  display: block;
  height: 4px;
  width: 164px;
  margin-top: 8px;
  background-color: #ffbf4f;
}

.program .right_img_row h4 {
  margin-bottom: 12px;
  font-family: "PFAgoraSansPro-Italic", "pfagorasanspro-italic_0", sans-serif;
  color: rgba(32, 32, 32, 0.5);
  font-size: 18px;
  text-transform: uppercase;
}

.program .right_img_row ul {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.program .right_img_row ul li {
  margin-bottom: 9px;
  font-size: 16px;
  line-height: 23px;
}

.teacher {
  padding-top: 89px;
  padding-bottom: 100px;
  background-color: #f9f9f9;
  overflow-x: hidden;
}

.teacher > .container {
  position: relative;
}

.teacher > .container .bg_text {
  position: absolute;
  left: 0;
  top: 531px;
  font-family: "Gilroy-Bold", "gilroy-bold", sans-serif;
  font-size: 200px;
  color: rgba(32, 32, 32, 0.05);
  text-transform: uppercase;
}

.teacher h2 {
  margin-bottom: 92px;
  font-family: "BebasNeueRegular", sans-serif;
  font-size: 48px;
  text-align: center;
}

.teacher h2 span {
  position: relative;
  z-index: 10;
}

.teacher h2 span:after {
  content: '';
  position: absolute;
  display: block;
  height: 16px;
  width: 259px;
  bottom: 7px;
  right: -4px;
  background-color: #fec157;
  z-index: -1;
}

.teacher .right_img_row {
  margin-bottom: 22px;
}

.teacher .right_img_row ul {
  max-width: 516px;
  margin-left: auto;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.teacher .right_img_row ul li {
  position: relative;
  margin-bottom: 14px;
  padding-left: 36px;
  font-size: 16px;
  line-height: 22px;
}

.teacher .right_img_row ul li span {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  height: 20px;
  width: 20px;
  font-family: "BebasNeueBold", sans-serif;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  background-color: #ffb32e;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.teacher .right_img_row .teacher_img_wrap {
  max-width: 420px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  text-align: center;
}

.teacher .right_img_row .teacher_img_wrap > img {
  -webkit-box-shadow: 0 15px 30px 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0 15px 30px 5px rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.teacher .right_img_row .teacher_img_wrap .teacher_flag {
  position: relative;
}

.teacher .right_img_row .teacher_img_wrap .teacher_flag img {
  margin-left: auto;
  margin-right: auto;
}

.teacher .right_img_row .teacher_img_wrap .teacher_flag .flag_desc {
  position: relative;
  top: -147px;
  font-family: "BebasNeueBold", sans-serif;
}

.teacher .right_img_row .teacher_img_wrap .teacher_flag .flag_desc h5 {
  margin-bottom: 10px;
  margin-top: 5px;
  font-size: 23px;
  color: #ffffff;
}

.teacher .right_img_row .teacher_img_wrap .teacher_flag .flag_desc h6 {
  font-size: 22px;
  line-height: 20px;
  color: #ffe8c0;
}

.teacher .left_img_row {
  margin-bottom: 22px;
}

.teacher .left_img_row ul {
  max-width: 516px;
  margin-left: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.teacher .left_img_row ul li {
  position: relative;
  margin-bottom: 14px;
  padding-left: 36px;
  font-size: 16px;
  line-height: 22px;
}

.teacher .left_img_row ul li span {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  height: 20px;
  width: 20px;
  font-family: "BebasNeueBold", sans-serif;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  background-color: #ffb32e;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.teacher .left_img_row .teacher_img_wrap {
  max-width: 420px;
  margin-left: 39px;
  text-align: center;
}

.teacher .left_img_row .teacher_img_wrap > img {
  -webkit-box-shadow: 0 15px 30px 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0 15px 30px 5px rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.teacher .left_img_row .teacher_img_wrap .teacher_flag {
  position: relative;
}

.teacher .left_img_row .teacher_img_wrap .teacher_flag img {
  margin-left: auto;
  margin-right: auto;
}

.teacher .left_img_row .teacher_img_wrap .teacher_flag .flag_desc {
  position: relative;
  top: -147px;
  font-family: "BebasNeueBold", sans-serif;
}

.teacher .left_img_row .teacher_img_wrap .teacher_flag .flag_desc h5 {
  margin-bottom: 0;
  font-size: 30px;
  color: #ffffff;
}

.teacher .left_img_row .teacher_img_wrap .teacher_flag .flag_desc h6 {
  font-size: 22px;
  line-height: 20px;
  color: #ffe8c0;
}

.reviews {
  padding-bottom: 51px;
}

.reviews .header_video {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0 15px 30px 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0 15px 30px 5px rgba(0, 0, 0, 0.15);
}

.reviews .header_video .youCover img {
  height: 360px;
  width: auto;
  margin-left: auto;
  margin-right: auto;
}

.reviews h2 {
  margin-bottom: 89px;
  font-family: "BebasNeueRegular", sans-serif;
  font-size: 48px;
}

.reviews h2 span {
  position: relative;
  z-index: 10;
}

.reviews h2 span:after {
  content: '';
  position: absolute;
  display: block;
  height: 16px;
  width: 137px;
  bottom: 7px;
  right: -5px;
  background-color: #fec157;
  z-index: -1;
}

.reviews .start_training_btn {
  margin-top: 50px;
}

.reviews_1 .container {
  padding-top: 89px;
  //background: url("../img/icons/inst_smile_1.png") no-repeat left 115px top 124px, url("../img/icons/inst_smile_2.png") no-repeat right 146px top 71px, url("../img/icons/inst_smile_5.png") no-repeat left 260px bottom 3px, url("../img/icons/inst_smile_4.png") no-repeat right 59px bottom 11px;
}

.reviews_2 {
  padding-top: 50px;
  padding-bottom: 0;
  background-color: #f9f9f9;
  overflow-x: hidden;
}

.reviews_2 .container {
  position: relative;
  padding-bottom: 101px;
  //background: url("../img/icons/inst_smile_1.png") no-repeat left 115px bottom 56px, url("../img/icons/inst_smile_3.png") no-repeat right 159px bottom 104px;
}

.reviews_2 .container .bg_text {
  position: absolute;
  right: -256px;
  top: 224px;
  font-family: "Gilroy-Bold", "gilroy-bold", sans-serif;
  font-size: 200px;
  color: rgba(32, 32, 32, 0.05);
  text-transform: uppercase;
}

.reviews_video_col {
  margin-bottom: 51px;
}

.reviews_video_col.mb_0 {
  margin-bottom: 0;
}

.reviews_video_col .video_desc {
  height: 65px;
  padding-top: 17px;
  padding-left: 118px;
  background: url("../img/icons/inst_reviews_flag1.jpg") no-repeat left 66px top;
}

.reviews_video_col .video_desc h4 {
  margin-bottom: 2px;
  font-size: 18px;
}

.reviews_video_col .video_desc p {
  font-size: 16px;
  color: #676767;
}

.benefits {
  padding-top: 89px;
  padding-bottom: 51px;
}

.benefits h2 {
  margin-bottom: 101px;
  font-family: "BebasNeueRegular", sans-serif;
  font-size: 48px;
  text-align: center;
}

.benefits h2 span {
  position: relative;
  z-index: 10;
}

.benefits h2 span:after {
  content: '';
  position: absolute;
  display: block;
  height: 16px;
  width: 153px;
  bottom: 7px;
  right: 4px;
  background-color: #fec157;
  z-index: -1;
}

.benefits .ben_left_img_row {
  margin-bottom: 84px;
}

.benefits .ben_left_img_row h4 {
  margin-bottom: 14px;
  font-size: 32px;
}

.benefits .ben_left_img_row p {
  font-size: 18px;
  line-height: 22px;
  min-height: 24px;
  margin-bottom: 15px;
  padding-left: 38px;
  padding-right: 20px;
  background: url("../img/icons/inst_advantages_arrow.jpg") no-repeat left top;

  &:last-of-type {
    margin-bottom: 15px;
  }
}

.benefits .ben_left_img_row p b {
  font-family: "PFAgoraSansPro-Bold", "pfagorasanspro-bold_0", sans-serif;
}

.benefits .ben_left_img_row .ben_desc_wrap {
  max-width: 511px;
  margin-right: 0;
  margin-left: auto;
  padding-top: 33px;
  padding-bottom: 16px;
  padding-left: 30px;
  border-left: 8px solid #ffcf7b;
  background: url("../img/inst_benefits_line_left.jpg") no-repeat top left, url("../img/inst_benefits_line_left.jpg") no-repeat bottom left;
}

.benefits .ben_right_img_row h4 {
  margin-bottom: 14px;
  font-size: 22px;
}

.benefits .ben_right_img_row p {
  font-size: 16px;
  line-height: 22px;
}

.benefits .ben_right_img_row p b {
  font-family: "PFAgoraSansPro-Bold", "pfagorasanspro-bold_0", sans-serif;
}

.benefits .ben_right_img_row .ben_desc_wrap {
  max-width: 511px;
  margin-left: 0;
  padding-top: 33px;
  padding-bottom: 16px;
  padding-right: 40px;
  border-right: 8px solid #ffcf7b;
  background: url("../img/inst_benefits_line_right.jpg") no-repeat top left, url("../img/inst_benefits_line_right.jpg") no-repeat bottom left;
}

.benefits .img_col {
  text-align: center;
}

.benefits .img_col img {
  position: relative;
  top: -13px;
}

.benefits .start_training_btn {
  margin-top: 66px;
}

.benefits_2 {
  padding-top: 62px;
  background-color: #f9f9f9;
}

.benefits_3 {
  padding-top: 62px;
}

.benefits_4 {
  padding-top: 62px;
  padding-bottom: 102px;
  background-color: #f9f9f9;
}

.acquirement {
  padding-top: 89px;
  padding-bottom: 51px;
}

.acquirement h2 {
  margin-bottom: 101px;
  font-family: "BebasNeueRegular", sans-serif;
  font-size: 48px;
  text-align: center;
}

.acquirement h2 span {
  position: relative;
  z-index: 10;
}

.acquirement h2 span:after {
  content: '';
  position: absolute;
  display: block;
  height: 16px;
  width: 417px;
  bottom: 7px;
  right: -3px;
  background-color: #fec157;
  z-index: -1;
}

.acquirement .start_training_btn {
  margin-top: 80px;
}

.acquirement_2 {
  padding-bottom: 100px;
  background-color: #f9f9f9;
}

.img_right_row {
  margin-bottom: 100px;
}

.img_right_row h4 {
  margin-bottom: 29px;
  padding-left: 35px;
  font-size: 22px;
  background: url("../img/icons/inst_acquirement_cross.png") no-repeat top left;
}

.img_right_row ul {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.img_right_row ul li {
  position: relative;
  margin-bottom: 14px;
  padding-left: 35px;
  font-size: 16px;
  line-height: 22px;
}

.img_right_row ul li:before {
  content: '';
  position: absolute;
  display: block;
  top: 6px;
  left: 16px;
  height: 8px;
  width: 8px;
  background-color: #ffb32e;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.img_right_row .acquirement_desc {
  max-width: 460px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 27px;
}

.img_left_row h4 {
  margin-bottom: 29px;
  padding-left: 35px;
  font-size: 22px;
  background: url("../img/icons/inst_acquirement_cross.png") no-repeat top left;
}

.img_left_row ul {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.img_left_row ul li {
  position: relative;
  margin-bottom: 14px;
  padding-left: 35px;
  font-size: 16px;
  line-height: 22px;
}

.img_left_row ul li:before {
  content: '';
  position: absolute;
  display: block;
  top: 6px;
  left: 16px;
  height: 8px;
  width: 8px;
  background-color: #ffb32e;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.img_left_row .acquirement_desc {
  max-width: 460px;
  margin-left: 0;
  padding-top: 27px;
}

.how_start {
  overflow: hidden;
}

.how_start > .container {
  position: relative;
  padding-top: 89px;
  padding-bottom: 71px;
}

.how_start h2 {
  margin-bottom: 87px;
  font-family: "BebasNeueRegular", sans-serif;
  font-size: 48px;
  text-align: center;
}

.how_start h2 span {
  position: relative;
  z-index: 10;
}

.how_start h2 span:after {
  content: '';
  position: absolute;
  display: block;
  height: 16px;
  width: 124px;
  bottom: 7px;
  right: -3px;
  background-color: #fec157;
  z-index: -1;
}

.how_start .item_left_row {
  margin-bottom: 29px;
}

.how_start .item_left_row .start_desc_wrap {
  position: relative;
  padding: 50px 30px 25px 99px;
  border: 1px solid #ffb32e;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.how_start .item_left_row .start_desc_wrap .num_start {
  position: absolute;
  display: inline-block;
  top: 14px;
  left: 30px;
  font-family: "BebasNeueBold", sans-serif;
  font-size: 48px;
  color: #ffbf4f;
  text-shadow: 0 5px 30px rgba(197, 149, 65, 0.25);
}

.how_start .item_left_row .start_desc_wrap h5 {
  margin-bottom: 13px;
  font-family: "PFAgoraSansPro-Bold", "pfagorasanspro-bold_0", sans-serif;
  font-size: 18px;
}

.how_start .item_left_row .start_desc_wrap p {
  font-size: 16px;
}

.how_start .item_right_row {
  margin-bottom: 29px;
}

.how_start .item_right_row .start_desc_wrap {
  position: relative;
  padding: 50px 30px 25px 99px;
  border: 1px solid #ffb32e;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.how_start .item_right_row .start_desc_wrap .num_start {
  position: absolute;
  display: inline-block;
  top: 14px;
  left: 30px;
  font-family: "BebasNeueBold", sans-serif;
  font-size: 48px;
  color: #ffbf4f;
  text-shadow: 0 5px 30px rgba(197, 149, 65, 0.25);
}

.how_start .item_right_row .start_desc_wrap h5 {
  margin-bottom: 13px;
  font-family: "PFAgoraSansPro-Bold", "pfagorasanspro-bold_0", sans-serif;
  font-size: 18px;
}

.how_start .item_right_row .start_desc_wrap p {
  font-size: 16px;
}

.how_start .col-right {
  position: relative;
}

.how_start .start_training_btn {
  top: 50px;
  left: 113px;
  position: absolute;
}

.how_start .start_training_btn:before {
  content: url("../img/icons/inst_start_arrow.png");
  position: absolute;
  top: -36px;
  left: -70px;
  height: 75px;
  width: 55px;
}

.how_start .shell_1 {
  position: absolute;
  top: 20px;
  left: 57px;
}

.how_start .shell_2 {
  position: absolute;
  top: 54px;
  right: 176px;
}

.how_start .shell_3 {
  position: absolute;
  top: 140px;
  left: 23px;
}

.how_start .shell_4 {
  position: absolute;
  top: 69px;
  left: 104px;
}

.how_start .shell_5 {
  position: absolute;
  top: 52px;
  right: 4px;
}

.how_start .shell_6 {
  position: absolute;
  top: 84px;
  left: 37px;
}

.how_start .shell_7 {
  position: absolute;
  top: 50px;
  right: 155px;
}

.how_start .shell_8 {
  position: absolute;
  bottom: -2px;
  left: 155px;
}

.training_price {
  padding-top: 89px;
  background-color: #f9f9f9;
  overflow-x: hidden;
}

.training_price > .container {
  position: relative;
}

.training_price > .container .price_bg1 {
  position: absolute;
  top: 630px;
  left: -137px;
}

.training_price > .container .price_bg2 {
  position: absolute;
  bottom: 0;
  right: -171px;
}

.training_price h2 {
  margin-bottom: 87px;
  font-family: "BebasNeueRegular", sans-serif;
  font-size: 48px;
  text-align: center;
}

.training_price h2 span {
  position: relative;
  z-index: 10;
}

.training_price h2 span:after {
  content: '';
  position: absolute;
  display: block;
  height: 16px;
  width: 94px;
  bottom: 7px;
  right: -4px;
  background-color: #fec157;
  z-index: -1;
}

.training_price h3 {
  position: relative;
  margin-bottom: 52px;
  font-family: "BebasNeueRegular", sans-serif;
  font-size: 32px;
  text-align: center;
  text-transform: uppercase;
}

.training_price h3:after {
  content: "";
  position: absolute;
  display: block;
  height: 4px;
  max-width: 165px;
  width: 100%;
  margin-left: 50%;
  left: -83px;
  bottom: -17px;
  background-color: #ffbf4f;
}

.training_price .rate_col:nth-of-type(1) .rate {
  margin-right: 0;
  margin-left: auto;
}

.training_price .rate_col:nth-of-type(2) .rate {
  margin-right: auto;
  margin-left: auto;
}

.training_price .rate_col:nth-of-type(3) .rate {
  margin-right: auto;
  margin-left: 0;
}

.training_price .rate_col:nth-of-type(4) {
  margin-bottom: 100px;
}

.training_price .rate_col:nth-of-type(4) .rate {
  position: relative;
  margin-right: auto;
  margin-left: auto;
}

.training_price .rate_col {
  position: relative;
  margin-bottom: 80px;
}

.training_price .orange_line_bg {
  position: absolute;
  display: block;
  top: 45px;
  height: 10px;
  width: 420px;
  background-color: #fdc057;
  margin-left: 50%;
  left: -210px;
}

.training_price .rate {
  max-width: 322px;
  padding-top: 33px;
  padding-bottom: 48px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 15px 30px 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0 15px 30px 5px rgba(0, 0, 0, 0.15);
}

.training_price .rate ul {
  margin-bottom: 10px;
  padding-left: 26px;
  padding-right: 20px;
  list-style: none;
}

.training_price .rate ul li {
  position: relative;
  margin-bottom: 14px;
  padding-left: 19px;
  font-size: 16px;
  line-height: 22px;
}

.training_price .rate ul li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 6px;
  display: block;
  height: 8px;
  width: 8px;
  background-color: #ffb32e;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.training_price .rate ul li:last-of-type {
  margin-bottom: 0;
}

.training_price .rate .start_training_btn {
  margin-top: 15px;
}

.training_price .price_now {
  margin-bottom: 3px;
  padding-left: 44px;
  font-size: 16px;
}

.training_price .price_now b {
  font-family: "BebasNeueRegular", sans-serif;
  font-size: 32px;
  color: #e31616;
}

.training_price .price_now b span {
  font-size: 18px;
}

.training_price .price {
  padding-left: 44px;
  font-size: 14px;
  color: #7b7b7b;
}

.training_price .price b {
  font-family: "BebasNeueRegular", sans-serif;
  font-size: 22px;
}

.training_price .price b span {
  font-size: 12px;
}

.faq > .container {
  padding-top: 88px;
  background: url("../img/inst_faq_bg1.jpg") no-repeat center top, url("../img/inst_faq_bg2.jpg") no-repeat center bottom;
}

.faq h2 {
  margin-bottom: 60px;
  font-family: "BebasNeueRegular", sans-serif;
  font-size: 48px;
  text-align: center;
}

.faq h2 span {
  position: relative;
  z-index: 10;
}

.faq h2 span:after {
  content: '';
  position: absolute;
  display: block;
  height: 16px;
  width: 150px;
  bottom: 7px;
  right: -6px;
  background-color: #fec157;
  z-index: -1;
}

.faq ul {
  margin-bottom: 0;
  padding-left: 66px;
  padding-right: 66px;
  list-style: none;
}

.faq ul li {
  position: relative;
  padding-top: 23px;
  padding-left: 31px;
  border-bottom: 2px solid #ffefc4;
}

.faq ul li img {
  position: absolute;
  top: 11px;
  left: -32px;
}

.faq ul li h4 {
  margin-bottom: 11px;
  font-family: "PFAgoraSansPro-Bold", "pfagorasanspro-bold_0", sans-serif;
  font-size: 18px;
}

.faq ul li p {
  font-size: 16px;
  line-height: 22px;
}

.faq .start_training_btn {
  margin: 80px auto 96px;
}

footer {
  padding-top: 16px;
  padding-bottom: 54px;
  background-color: #f9f9f9;
}

.footer_nav_row {
  margin-bottom: 47px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer_nav_row > div:first-of-type {
  padding-right: 0;
}

a.footer_logo {
  font-family: "BebasNeueRegular", sans-serif;
  font-size: 22px;
  color: #202020;
  line-height: 24px;
  text-decoration: none;
  -webkit-transition: all .25s ease;
  -o-transition: all .25s ease;
  transition: all .25s ease;
}

a.footer_logo:hover {
  color: #ffb528;
}

.footer_nav ul {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  text-align: center;
}

.footer_nav ul li {
  line-height: 28px;
}

.footer_nav ul li:last-of-type {
  margin-right: 0;
}

.footer_nav ul li a {
  font-size: 18px;
  color: #202020;
  -webkit-transition: all .25s ease;
  -o-transition: all .25s ease;
  transition: all .25s ease;
  text-decoration: none;
  border-bottom: 4px solid transparent;
}

.footer_nav ul li a:hover {
  border-bottom: 4px solid #ffb528;
}

.footer_phone {
  margin-left: auto;
  width: 188px;
}

.footer_phone p {
  margin-bottom: 0;
  font-family: "BebasNeueRegular", sans-serif;
  font-size: 28px;
  line-height: 27px;
}

.footer_phone span {
  font-size: 16px;
  color: #ffbf4f;
}

.footer_phone span:first-of-type {
  font-size: 16px;
  color: #676767;
}

a.confidentiality {
  font-size: 16px;
  color: #202020;
  border-bottom: 1px solid #202020;
  text-decoration: none;
  -webkit-transition: all .25s ease;
  -o-transition: all .25s ease;
  transition: all .25s ease;
}

a.confidentiality:hover {
  color: #ffb528;
  border-bottom: 1px solid #ffb528;
}

.footer_social {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.footer_social span {
  margin-right: 20px;
  font-size: 16px;
}

.footer_social a {
  display: block;
  height: 40px;
  width: 40px;
  margin-right: 20px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: all .25s ease;
  -o-transition: all .25s ease;
  transition: all .25s ease;
}

.footer_social a:last-of-type {
  margin-right: 0;
}

.footer_social a:hover {
  border-color: #fa6185;
}

.footer_social a.footer_vk {
  background: url("../img/icons/inst_vk.png") no-repeat center;
  border: 1px solid #005791;
}

.footer_social a.footer_vk:hover {
  background: url("../img/icons/inst_vk_hover.png") no-repeat center;
  border-color: #0076c4;
}

.footer_social a.footer_telegram {
  background: url("../img/icons/inst_telegram.png") no-repeat center;
  border: 1px solid #34a4ef;
}

.footer_social a.footer_telegram:hover {
  background: url("../img/icons/inst_telegram_hover.png") no-repeat center;
  border-color: #128cde;
}

.footer_social a.footer_viber {
  background: url("../img/icons/inst_viber.png") no-repeat center;
  border: 1px solid #9c21bf;
}

.footer_social a.footer_viber:hover {
  background: url("../img/icons/inst_viber_hover.png") no-repeat center;
  border-color: #b837dc;
}

.footer_social a.footer_whatsapp {
  background: url("../img/icons/inst_whatsapp.png") no-repeat center;
  border: 1px solid #28db23;
}

.footer_social a.footer_whatsapp:hover {
  background: url("../img/icons/inst_whatsapp_hover.png") no-repeat center;
  border-color: #20af1c;
}

.footer_social a.footer_facebook {
  background: url("../img/icons/inst_facebook.png") no-repeat center;
  border: 1px solid #005791;
}

.footer_social a.footer_facebook:hover {
  border-color: #0076c4;
  background: url("../img/icons/inst_facebook_hover.png") no-repeat center;
}

.embed-responsive-16by9::before {
  padding-top: 0;
}

header .footer_social {
  margin-top: 34px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

header .header_video_wrap .subscribe_it p {
  max-width: 320px;
  margin-bottom: 0;
  font-size: 18px;
}

.subscribe_it p {
  margin-bottom: 0;
  max-width: 320px;
  font-size: 18px;
}

.advantages .social_wrap {
  margin-top: 80px;
}

.advantages .social_wrap .subscribe_it p {
  margin-bottom: 0;
  max-width: 100%;
  font-size: 18px;
  text-align: center;
}

.advantages .social_wrap .footer_social {
  margin-top: 22px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.program_video .social_wrap {
  margin-bottom: 76px;
}

.program_video .social_wrap .subscribe_it p {
  margin-bottom: 0;
  max-width: 100%;
  font-size: 18px;
  text-align: center;
}

.program_video .social_wrap .footer_social {
  margin-top: 22px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.program_video .left_img_row {
  margin-bottom: 59px;
}

.teacher_video .social_wrap .subscribe_it p {
  margin-bottom: 0;
  max-width: 100%;
  font-size: 18px;
  text-align: center;
}

.teacher_video .social_wrap .footer_social {
  margin-top: 22px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.reviews_video .social_wrap .subscribe_it p {
  margin-bottom: 0;
  max-width: 100%;
  font-size: 18px;
  text-align: center;
}

.reviews_video .social_wrap .footer_social {
  margin-top: 22px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.footer_video {
  border-top: 4px solid #ffb32e;
}

.thank_you .header_video_wrap {
  background: none;
  padding-top: 216px;
}

.thank_you .header_video_wrap h2 {
  max-width: 100%;
  margin-bottom: 42px;
  font-size: 46px;
}

.thank_you .header_video_wrap h2 span {
  position: relative;
  z-index: 10;
}

.thank_you .header_video_wrap h2 span:after {
  content: '';
  position: absolute;
  display: block;
  height: 16px;
  width: 142px;
  bottom: 7px;
  right: -6px;
  background-color: #fec157;
  z-index: -1;
}

.thank_you .header_video_wrap h4 {
  font-size: 24px;
}

.thank_you .header_video_wrap h4:last-of-type {
  margin-bottom: 133px;
}

.thank_you .footer_social {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 24px;
}

.thank_desc {
  text-align: center;
}

.thank_desc p {
  margin-bottom: 0;
  font-size: 18px;
  line-height: 24px;
}

.thank_phone {
  margin-top: 24px;
  font-size: 18px;
}

.thank_phone b {
  font-family: "PFAgoraSansPro-Bold", "pfagorasanspro-bold_0", sans-serif;
}

header.thank_you {
  -webkit-background-size: cover;
  background-size: cover;
}

/*CUSTOM*/

.training_price h4 {
  font-size: 24px;
  text-align: center;
  font-family: "PFAgoraSansPro-Regular";
}

.training_price h4 b {
  color: #ffbf4f;
}

.training_price h4:last-of-type {
  margin-bottom: 60px;
}

.training_price h2 {
  margin-bottom: 34px;
}

.teacher_desc {
  text-align: center;
  font-size: 22px;
  line-height: 30px;
  margin-top: -50px;
}

.col_program {
  padding-top: 50px;
}

.program h5 {
  margin-bottom: 30px;
}

.prev_arrow {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  height: 40px;
  width: 40px;
  left: 30px;
  top: 250px;
  margin-top: -20px;
  border-radius: 50%;
  background-color: #FFB32F;
  cursor: pointer;
  transition: ease all 0.25s;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }

  i {
    font-size: 36px;
  }
}

.next_arrow {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  height: 40px;
  width: 40px;
  left: 370px;
  top: 250px;
  margin-top: -20px;
  border-radius: 50%;
  background-color: #FFB32F;
  cursor: pointer;
  transition: ease all 0.25s;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }

  i {
    font-size: 36px;
  }
}

.row_fond {
  margin-bottom: 30px;
  align-items: center;
}

.politica_container {
  padding-top: 150px;
  padding-bottom: 100px;

  h4 {
    margin-top: 30px;
    font-family: "BebasNeueRegular", sans-serif;
    text-transform: uppercase;
  }

  p {
    margin-bottom: 0;
    font-size: 18px;
  }

  a {
    color: #000;
    text-decoration: none !important;
    border-bottom: 1px solid rgba(0,0,0,.7);
    transition: all ease .25s;

    &:hover {
      color: #FFB32F;
      border-bottom: 1px solid transparent;
    }
  }
}

.main_screen_form_wrap {
  position: relative;
  max-width: 390px;
  margin-left: auto;
  padding: 7px;
  background-color: #fff;
  border-radius: 10px;

  &:after {
    content: '';
    position: absolute;
    display: block;
    height: 7px;
    width: 90%;
    left: 50%;
    margin-left: -45%;
    bottom: -7px;
    background: #F0F0F0;
    border-radius: 0 0 10px 10px;
    z-index: 0;
  }
}

.main_screen_form {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 10;
  padding-top: 22px;
  padding-left: 27px;
  padding-right: 27px;
  padding-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 7px;
  background-color: #fff;

  h3 {
    margin-bottom: 22px;
    font-size: 24px;
    line-height: 28px;
    color: #81340a;
    text-align: center;
  }

  & > p {
    max-width: 290px;
    font-size: 14px;
    line-height: 20px;

    span {
      font-weight: 500;
      color: #81340a;
    }
  }

  form {
    margin-top: 18px;
    margin-bottom: 26px;
  }

  .chekbox_wrap {
    margin-top: 24px;
  }

  input[type=tel] {
    height: 70px;
    width: 100%;
    margin-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 16px;
    border: 1px solid #ededed;
    border-radius: 10px;
    background-color: #fff;
    transition: all ease .25s;
    outline: none !important;

    &:hover {
      border: 1px solid #FFB32F;
    }
  }

  input[type=checkbox] {
    display: none;
  }


  button {
    outline: none !important;
    border: none !important;
    height: 70px;
    width: 100%;
  }
}

.chekbox_wrap {

  .ui-button {
    margin: 0 5px 0 0;
    padding: 0;
    border-radius: 0;
    outline: none !important;
    border: none !important;
    background: none !important;
    box-shadow: none !important;
  }

  .ui-state-active {
    background: none !important;
    outline: none !important;
    border: none !important;
  }

  .ui-checkboxradio-label .ui-icon-background {
    position: relative;
    display: block;
    height: 16px;
    width: 16px;
    box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.25);
    background: #F9BD46;
    border-radius: 3px;
  }

  .ui-checkboxradio-checked {
    position: relative;

    &:after {
      content: url("../img/icons/checkbox_ico.png");
      position: absolute;
      display: block;
      height: 14px;
      width: 16px;
      top: -5px;
      left: 3px;
      z-index: 10;

    }
  }

  .checkbox_span {
    position: relative;
    top: -2px;
    font-size: 12px;
    line-height: 18px;
    color: rgba(32, 32, 32, .6);
  }
}
.order_form  {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.thanks_desc {
  padding-top: 100px;
  padding-bottom: 100px;

  h1 {
    font-family: BebasNeueBook;
    font-size: 60px;
    text-align: center;
  }

  h5 {
    font-size: 32px;
    text-align: center;

    a {
      color: #000;
      text-decoration: none !important;
      border-bottom: 1px solid rgba(0,0,0,.6);
      transition: all ease .25s;

      &:hover {
        color: #FFB32F;
        border-bottom: 1px solid transparent;
      }
    }
  }
}